<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.RIKindDesc }}</div>
      <div class="Summary"
        v-if="list.Summary">概述:{{list.Summary}}</div>
      <div v-if="list.ThematicUrl"
        class="essayImg">
        <img :src="list.ThematicUrl"
          alt="" />
      </div>
      <p class="essayContent"
        v-html="list.Content"></p>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetPartyInfo } from "@/api/RealInfo";
export default {
  data () {
    return {
      list: {},
      ActivityFrom: {},
    };
  },
  methods: {
    // 获取资讯详情
    getRealInfo () {
      this.ActivityFrom.riId = this.$route.params.Id;
      this.ActivityFrom.OpenID = 'gnjksdngjngdjkbjk'
      // getOpenId();
      WeGetPartyInfo(this.ActivityFrom).then((res) => {
        this.list = res.data.data;
        console.log(this.list);
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
      });
    },
  },
  mounted () {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getRealInfo();
  },
};
</script>
<style>
.essayContent img {
  width: 90%;
  margin: 5px 5%;
  display: block;
}
.Summary {
  margin: 5px 5%;
  width: 90%;
  font-size: 14px;
  color: #666;
}
</style>